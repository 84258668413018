
import { defineComponent, ref, watch } from "vue"
import FilterBaseV2, { IEmitRequestFlow, IEmitGlobalFields } from "@/layout/header/components/FilterBaseV2.vue"
import { useLoaderStore } from "@/store/LoaderStore"
import { ElConfigProvider, ElDatePicker } from "element-plus"
import ptBr from "element-plus/lib/locale/lang/pt-br"

export interface IFilterStockExpiredSearchForm {
  movement_id: string | null,
  startDate: string | null,
  endDate: string | null,
}

export default defineComponent({
  name: "FilterStockExpireListPage",
  components: {
    FilterBaseV2,
    ElConfigProvider,
    ElDatePicker
  },
  setup(props, { emit }) {
    /** Variables */
    const isActiveModal  = ref(false)
    const isCloseModal = ref(false)
    const loaderStore = useLoaderStore()
    const rangeDate = ref<any>("")
    const searchForm = ref<IFilterStockExpiredSearchForm>({
      movement_id: null,
      startDate: null,
      endDate: null,
    })

    /** Functions */
    function handleChangesGlobalFields(emit: IEmitGlobalFields) {
      // console.log("EmitGlobalFields: ", emit)
    }

    async function handleRequestFlow(emit: IEmitRequestFlow) {
      // console.log("EmitRequestFlow: ", emit)

      isCloseModal.value = false
      loaderStore.open()
      if(!emit.loading) {
        isCloseModal.value = true
        loaderStore.close()
      }
      if(emit.error)
        loaderStore.close()
    }

    /** Life Cycles */
    watch(() => rangeDate.value, () => {
      searchForm.value.startDate = (Array.isArray(rangeDate.value) && rangeDate.value.length > 1) ? rangeDate.value[0] : null
      searchForm.value.endDate = (Array.isArray(rangeDate.value) && rangeDate.value.length > 1) ? rangeDate.value[1] : null
    }, { deep: true })

    return {
      isActiveModal,
      isCloseModal,
      handleChangesGlobalFields,
      handleRequestFlow,
      searchForm,
      rangeDate,
      ptBr,
    }
  },
})
